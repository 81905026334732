
//  Collections Contract    :     0x5AeE2C07420a769634C5028744f4a1c71CB64eDc 
//  Staking Contract        :     0x09E5D9733f76D244C4a0AE7cC154C7173D076510


export const NFTCONTRACT = "0x5AeE2C07420a769634C5028744f4a1c71CB64eDc";
export const STAKINGCONTRACT = "0x09E5D9733f76D244C4a0AE7cC154C7173D076510"; 
export const polygonscanapikey = "HR6CJBFJXBIQ95539B5KCQR8CZGV6E34AB";
export const polygonscanapi = "https://api-testnet.polygonscan.com/api";
export const moralisapi = "https://deep-index.moralis.io/api/v2/";
// export const moralisapikey = "2VBV4vaCLiuGu6Vu7epXKlFItGe3jSPON8WV4CrXKYaNBEazEUrf1xwHxbrIo1oM";
export const moralisapikey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImJlZmM3ZTZhLTE0MTMtNDgzNS1iMTE3LTI5ZDVkNmU0MWFmMiIsIm9yZ0lkIjoiMzQ5MDg1IiwidXNlcklkIjoiMzU4ODExIiwidHlwZUlkIjoiZjE4MWU5ZmUtN2MzZi00OGI1LTlkZWEtM2M5OTNjOWEwNTM4IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODk3ODQ3OTYsImV4cCI6NDg0NTU0NDc5Nn0.0ukcugfA1MCPDhw9O-LghJXL_gWAj32JCxyWwL_Ngc0";
export const nftpng = "https://ipfs.io/ipfs/QmavM8Zpo9bD3r4zEnhbbBLLvHyfr1YL7f1faG3ovaeSSG/";


// export const nftpng = "https://ipfs.io/ipfs/QmetESUb7wTPr8mBHWFsiSKMw6HTLWUGoL8DrkcaY9TTyh/";

// Morali ProjectId   :    f181e9fe-7c3f-48b5-9dea-3c993c9a0538